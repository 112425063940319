import React from "react"
import NotFound from "../components/not-found"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <>
    <SEO title="404 - Not found" />
    <NotFound
      topLine="/404 - NOT FOUND"
      middleLine="Missing page or digital art installation? You decide."
      bottomLine="In the meantime we hope you can find what you’re looking for:"
    />
  </>
)

export default NotFoundPage
